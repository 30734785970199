import React from 'react'
import { graphql } from 'gatsby'
import scrollTo from 'gatsby-plugin-smoothscroll'
import SEO from '../components/seo'
import BlockContent from '../components/block-content'
import ProductCard from '../components/product-card'
import { BlockContentToText } from '../utils/block-content-to-text'
import Button from '../components/button'
import Usp from '../components/usp'
import GlobalUSP from '../components/global-usp'
import Figure from '../components/figure'
import { faqToAccordion, mapEdgesToNodes } from '../utils/helpers'
import Accordion from '../components/accordion'
import BlogPostPreview from '../components/blog-post-preview'
import Physician from '../components/physician'
import paymentIcon from '../images/pay-circle.svg'
import Breadcrumbs from '../components/breadcrumbs'
import { ProductImpressions } from '../components/enhanced-ecommerce'

const CategoryTemplate = props => {
  const { data, errors } = props
  const category = data && data.category
  const products = data && data.products
  const blog = data && data.blog && mapEdgesToNodes(data.blog)
  const pageDescriptionBlock = data && data.site && data.site.pageDescriptionBlock
  const metaDescription = (category.seo && category.seo.description) || BlockContentToText(category.description)

  return (
    <>
      <Breadcrumbs crumbs={[ category ]} />
      <div className='max-h-full overflow-auto'>
        {errors && <SEO title='GraphQL Error' />}
        {category && <SEO page={category} description={metaDescription} image={category.image} />}

        <div className='bg-primary-background-sand relative header-offset'>
          <header className='block lg:grid grid-cols-2'>
            <div className='flex flex-col justify-center w-full px-5 pt-12 pb-16 lg:pt-16 lg:px-12 xl:px-20'>
              <h1>La oss hjelpe deg med {category.title.toLowerCase()}</h1>

              <div>
                {category.description && <BlockContent className='lead mb-8' blocks={category.description} />}

                {products && products.edges.length > 0 && <Button variant={`outline`} rounded={true} border={true} size={`large`} onClick={() => scrollTo('#produkter')}>Se hva vi kan hjelpe deg med</Button>}
              </div>
            </div>

            {category.image && (
              <Figure
                node={category.image}
                className='w-full h-full'
              />
            )}
          </header>

          <div className='bg-primary-background-beige p-8 lg:py-12 xl:py-24'>
            {category.usp && category.usp.length > 0 && (
              <div className='block lg:flex justify-between content-center max-w-screen-xl mx-auto'>
                {category.usp.map((usp, i) => (
                  <Usp text={usp.text} image={usp.image} key={`global-usp-${i}`} />
                ))}
              </div>
            )}

            {(!category.usp || category.usp.length === 0) && (
              <GlobalUSP className='block lg:flex justify-between content-center max-w-screen-xl mx-auto' />
            )}
          </div>

          {category.howItWorksText && (
            <section className='py-20 lg:py-32 px-8 lg:px-0 bg-primary'>
              <div className='mb-12 lg:mb-20 lg:px-12 max-w-lg lg:max-w-screen-xl lg:mx-auto'>
                <h2 className='display'>Slik fungerer det</h2>
              </div>

              <div className='lg:flex max-w-lg lg:max-w-screen-xl lg:mx-auto'>
                {category.howItWorksText.map((row, i) => (
                  <div key={i} className='mb-12 lg:mb-0 lg:flex-1 lg:px-12'>
                    {row.image && <Figure node={row.image} className='h-auto mb-4' />}
                    <h3 className='h4 font-body'>{row.title}</h3>
                    <BlockContent blocks={row.text} className='small' />
                  </div>
                ))}
              </div>
            </section>
          )}

          {products && products.edges.length > 0 && (
            <div className="relative overflow-hidden">
              <div className="radial-gradient top right z-0"></div>
              <section id='produkter' className='px-4 py-20 lg:py-32 text-center relative'>
                <div className='max-w-screen-lg mx-auto'>
                  <h2 className='text-black display mb-12'>Hva vi kan hjelpe deg med</h2>

                  <div className='flex flex-wrap justify-center mb-10'>
                    {products.edges.map(product => (
                      <div className='w-full md:w-1/2 flex mb-4 md:mb-8' key={product.node.id}>
                        <ProductCard className='h-full w-full md:mx-4 bg-primary-background-beige' product={product.node} />
                      </div>
                    ))}
                  </div>

                  <div className='flex flex-col md:flex-row items-center justify-center'>
                    <img src={paymentIcon} alt='' className='m-6' />
                    <div className='h4 mb-0 text-black font-body font-bold text-center md:text-left max-w-5/6'>Hvis vi ikke kan gi deg resept, betaler du heller ikke for konsultasjonen.</div>
                  </div>
                </div>
              </section>
            </div>
          )}

          {category.experts && category.experts.length > 0 && (
            <section id='eksperter' className='px-8 py-20 lg:py-32 bg-primary-background'>
              <h2 className='font-bold text-black font-body text-center mb-12'>Våre leger</h2>

              {category.experts.map((expert, i) => (
                <Physician {...expert} key={i} />
              ))}
            </section>
          )}

          {category.faq && (
            <div className='bg-red-lighter py-20 lg:py-32 px-4 md:px-12'>
              <Accordion className='max-w-screen-sm mx-auto' items={faqToAccordion(category.faq)}>
                <h2 className='display mb-4'>Spørsmål og svar fra dere</h2>
                <p className='lead mb-12'>Dette høres kanskje for enkelt ut til å være sant? Her får du svarene! Kontakt oss, se vår FAQ eller send oss en mail hvis du har andre spørsmål</p>
              </Accordion>
            </div>
          )}

          {blog && blog.length > 0 && (
            <div className='bg-white'>
              <div className='py-20 lg:py-32 px-4 max-w-screen-lg mx-auto'>
                <h2 className='display text-black text-center mb-8 lg:mb-12'>Artikler fra bloggen</h2>

                <div className='flex flex-wrap md:-mx-4'>
                  {blog.map(node => (
                    <BlogPostPreview className='my-8 w-full md:px-4 md:w-2/4' key={node.id} compact {...node} />
                  ))}
                </div>
              </div>
            </div>
          )}

          {pageDescriptionBlock && pageDescriptionBlock.text && (
            <div className='lg:flex items-center bg-primary-background-beige-dark'>
              {pageDescriptionBlock.image && (
                <Figure node={pageDescriptionBlock.image} className='flex-1' aspectRatio={1} />
              )}

              <div className='flex-1 flex flex-col justify-center px-4 py-8 pb-16 md:px-20 md:py-10 lg:px-32 lg:max-w-3/6'>
                {pageDescriptionBlock.title && (
                  <h2 className='font-body font-bold'>{pageDescriptionBlock.title}</h2>
                )}
                <BlockContent className='paragraph-3' blocks={pageDescriptionBlock.text} />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default CategoryTemplate

export const query = graphql`
  query CategoryPageQuery($id: String!) {
    category: sanityCategory(id: {eq: $id}) {
      id
      image {
        asset {
          id
          fluid(maxWidth: 1440) {
            ...GatsbySanityImageFluid
          }
        }
        alt
      }
      description: _rawDescription(resolveReferences:{maxDepth:100})
      title
      slug {
        current
      }
      usp {
        image {
          asset {
            id
            fluid(maxWidth: 64) {
              ...GatsbySanityImageFluid
            }
          }
        }
        text
      }
      howItWorksText {
        title
        text: _rawText(resolveReferences:{maxDepth:100})
        image {
          asset {
            id
            fluid(maxWidth: 200) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      experts {
        id
        name
        image {
          alt
          asset {
            fluid(maxWidth: 452) {
              ...GatsbySanityImageFluid
            }
          }
        }
        description: _rawDescription
        position
      }
      faq {
        question
        _rawAnswer(resolveReferences:{maxDepth:100})
      }
      seo {
        title
        description
      }
    }

    products: allSanityProduct(filter: {
      category: {id: {eq: $id}}
    }) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          images {
            asset {
              fluid(maxWidth: 300) {
                ...GatsbySanityImageFluid
              }
            }
            alt
          }
          category {
            slug {
              current
            }
          }
          lead: _rawLead(resolveReferences:{maxDepth:100})
          defaultPrice
          label
        }
      }
    }

    blog: allSanityBlog(filter: {
      category: {id: {eq: $id}}
    }, sort: {
      fields: _createdAt,
      order: DESC
    }, limit: 2) {
      edges {
        node {
          ...BlogPostPreview
        }
      }
    }

    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      pageDescriptionBlock {
        title,
        text: _rawText(resolveReferences:{maxDepth:100})
        image {
          asset {
            id
            fluid(maxWidth: 1280) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`